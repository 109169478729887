import { Common } from '~/models/common';
import type { FooterMenu } from '~/models/footer-menu';

const collection = 'footer_menu';

export const footerMenuService = {
	fetch: async (): Promise<FooterMenu.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<FooterMenu.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'to.url'],
			},
		});
	},
};
