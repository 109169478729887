<template lang="pug">
div(class='MobileMenu')
	div(class='Top')
		Logo
		div(v-if='contacts?.logoTitle', class='LogoTitle', v-html='$f.forceTransfer(contacts.logoTitle, 2)')
	div(class='Middle')
		UiScrollShadow
			Navigation(:items='menuState', vertical)
	div(class='Bottom')
		NuxtLink(v-if='contacts?.phone', :href='$f.tel(contacts.phone)', class='ExtraButton')
			div(class='Icon VariantPhone')
				UiIcon(name='system/phone', size='xl')
			div(class='Text') {{ contacts.phone }}

		NuxtLink(v-if='contacts?.whatsapp', :href='contacts.whatsapp', class='ExtraButton')
			div(class='Icon VariantWhatsapp')
				UiIcon(name='social/whatsapp-inline', size='xl')
			div(class='Text') {{ contacts.whatsappText }}

		NuxtLink(v-if='contacts?.telegram', :href='contacts.telegram', class='ExtraButton')
			div(class='Icon VariantTelegram')
				UiIcon(name='social/telegram', size='xl')
			div(class='Text') {{ contacts.telegramText }}
</template>

<script lang="ts" setup>
import Navigation from '../header/navigation.vue';

const { contacts, fetchContacts } = useContacts();
const { menuState, fetchMenu } = useMenu();

await fetchContacts();
await fetchMenu();
</script>

<style lang="less" scoped>
.MobileMenu {
	min-height: 42rem;
	max-height: 75vh;
	padding: 1.25rem 0;
	.absolute(10; none; 100% 0 none none);
	.border-radius(@BorderRadius);
	.box(grid; 100%; unset; @ColorWhite; 1rem; auto 1fr auto; 1fr; center; center);
	.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorBase, 20%));
	.calc(height; ~'100vh - 100% - 1rem');

	& > .Top {
		padding: 0 1.5rem;
		.grid(1 span);
		.box(grid; 100%; 1.875rem; none; 1rem; 1fr; auto 1fr; center; start);
		& > .Logo {
			.grid(1 span);
			.box(auto; 1.875rem);
		}
		& > .LogoTitle {
			.grid(1 span);
			.text(@ColorBase; 0.875rem 0.75rem @semibold);
		}
	}
	& > .Middle {
		overflow-y: auto;
		.grid(1 span; 1 span; stretch; stretch);
		& > * {
			padding: 0 1.875rem;
			.box-sizing();
		}
	}
	& > .Bottom {
		padding: 1.25rem 1.875rem 1.25rem 1.875rem;
		.grid(1 span; 1 span; center; start);
		.box(grid; auto; auto; none; 1rem; auto; 1fr; center; start);
	}

	& .ExtraButton {
		text-decoration: none;
		.box(grid; auto; auto; none; 0.5rem; 1fr; auto 1fr; center; start);
		& > .Icon {
			.text(@ColorWhite);
			.border-radius(@BorderRadiusFull);
			.box(grid; 3rem; 3rem; @ColorBase; 0; 1fr; 1fr; center; center);
			.transition(all; 250ms; 'sine');

			&.VariantPhone {
				.background(@ColorPrimary);
			}
			&.VariantWhatsapp {
				.background(@ColorWhatsapp);
			}
			&.VariantTelegram {
				.background(@ColorTelegram);
			}
		}
		& > .Text {
			.text(@ColorBase; 1.125rem 1.125rem @medium);
		}
	}

	/**
	* > 600px
	*/
	@media all and (min-width: @tablet-small) {
		max-width: 22rem;
	}
}
</style>
