<template lang="pug">
div(class='ToggleMenu', :class='{ Active: menuToggle }', @click='toggleMenu')
	span
</template>

<script lang="ts" setup>
const { toggleMenu, menuToggle } = useMenu();
</script>

<style lang="less" scoped>
.ToggleMenu {
	.border-radius(0.3125rem);
	.box(grid; 2rem; 2rem; @ColorWhite; 0; 1fr; 1fr; center; center);
	.transition(all; 250ms; 'sine');

	& > span {
		.relative();
		.grid(1 span);
		.border-radius(0.125rem);
		.box(45%; 5%; @ColorPrimary);
		.transition(all; 250ms; 'sine');
		&:before,
		&:after {
			content: '';
			.absolute(0);
			.border-radius(0.125rem);
			.box(100%; 100%; @ColorPrimary);
			.transition(all; 250ms; 'sine');
		}
		&:before {
			.translateY(-300%);
		}
		&:after {
			.translateY(300%);
		}
	}

	&.Active {
		.border-radius(100%);
		& > span {
			.background(transparent);
			&:before,
			&:after {
				.background(@ColorBase);
			}
			&:before {
				.rotate(-135deg);
				.translateY(0);
			}
			&:after {
				.rotate(135deg);
				.translateY(0);
			}
		}
	}

	@media all and (min-width: @mobile-small) {
		.box(2.5rem; 2.5rem);
	}
}
</style>
