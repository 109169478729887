<template lang="pug">
footer
	div(class='Footer Container')
		Logo

		div(v-if='contactsList.length', class='Contacts')
			div(v-for='contact in contactsList', :key='contact.id', class='Contact')
				div(class='Phone')
					div(class='Label') {{ contact.name }}
					NuxtLink(v-if='contact.phone', :href='$f.tel(contact.phone)') {{ contact.phone }}

				div(class='Address')
					div(class='Metro')
						UiIcon(name='system/metro', size='md')
						span {{ contact.subway }}
					div(class='Text') {{ contact.address }}

					UiButton(
						v-if='contact.mapLink',
						:href='contact.mapLink',
						theme='outline',
						target='_blank',
						:size='greaterThan("tablet") ? "sm" : "xs"',
						icon-right='system/arrow-top-right',
						rounded
					) {{ contact.mapText }}

		div(class='Social')
			div(v-if='variablesList.socialButtonsTitle', class='Title') {{ variablesList.socialButtonsTitle }}
			div(class='SocialButtons')
				template(v-for='social in socialsState', :key='social.id')
					NuxtLink(
						v-if='social.link && social.icon',
						class='Social',
						:href='social.link',
						:title='social.title',
						target='_blank'
					)
						UiIcon(:name='social.icon', size='lg')

		div(class='FooterInfo')
			nav(class='FooterLinks')
				NuxtLink(v-for='{ id, title, to } in footerMenuState', :key='id', :to='to.url') {{ title }}
			div(v-if='variablesList.copyright', class='FooterCopyright') {{ variablesList.copyright }}
</template>

<script lang="ts" setup>
const { greaterThan } = usePlatform();
const { socialsState, fetchSocials } = useSocials();
const { contactsList, fetchContacts } = useContacts();
const { variablesList, fetchVariables } = useVariables();
const { footerMenuState, fetchFooterMenu } = useFooterMenu();

await fetchSocials();
await fetchContacts();
await fetchVariables();
await fetchFooterMenu();
</script>

<style lang="less" scoped>
footer {
	.box(block; 100%; auto; @ColorBase);
}
.Footer {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	.box(grid; 100%; auto; none; 2rem 1rem; 2.5rem auto; 1fr; start; start);

	& > .Logo {
		.grid(1 span; 1 span);
		.box(auto; 100%);
	}

	& > .Contacts {
		.grid(1 span; 1 span);
		.box(grid; 100%; auto; none; 2rem 1rem; 1fr; repeat(2, 1fr); start; start);
		& > .Contact {
			.grid(1 span);
			.box(grid; 100%; 100%; none; 1rem; auto 1fr; 1fr; stretch; start);
			& > .Phone {
				.box(grid; 100%; auto; none; 0.25rem; auto; 1fr; center; start);
				& > .Label {
					.text(@ColorPrimary; 1.25rem 1rem @semibold);
				}
				& > a {
					.text(@ColorWhite; 1.25rem 1rem @semibold; none; none none);
				}
			}
			& > .Address {
				.box(grid; 100%; auto; none; 0.5rem; auto 1fr auto; 1fr; stretch; start);
				& > .Metro {
					.text(fade(@ColorWhite, 50); 1rem 0.875rem @medium);
					.box(grid; 100%; auto; none; 0.5rem; 1fr; auto 1fr; end; start);
				}
				& > .Text {
					max-width: 9rem;
					.text(@ColorWhite; 1rem 0.75rem @regular);
				}
			}
		}
	}

	& > .Social {
		.grid(1 span; 1 span);
		.box(grid; 100%; 100%; none; 1rem; auto 1fr; 1fr; stretch; start);
		& > .Title {
			.text(@ColorWhite; 1.25rem 1rem @semibold);
		}
		& > .SocialButtons {
			.text(@ColorBase; 1.125rem 1.125rem @medium);
			.box(grid; auto; auto; none; 1rem; 1fr; auto; center; start; column);
			& > .Social {
				.text(@ColorBase);
				.border-radius(@BorderRadiusFull);
				.box(grid; 2.5rem; 2.5rem; fade(@ColorWhite, 50%); 0; 1fr; 1fr; center; center);
				.transition(all; 250ms; 'sine');
				&:hover {
					.background(@ColorWhite);
				}
			}
		}
	}

	& > .FooterInfo {
		.grid(1 span; 1 span);
		.box(grid; 100%; 100%; none; 0.5rem; auto; 1fr; stretch; start);
		& > .FooterLinks {
			.grid(1 span);
			.text(@ColorWhite; 1rem 1rem @regular);
			.box(grid; 100%; 100%; none; 0.5rem; auto; 1fr; stretch; start; row);
		}
		& > .FooterCopyright {
			.grid(1 span);
			.text(fade(@ColorWhite, 50%); 1.375rem 1rem @regular);
		}
	}

	@media all and (min-width: @tablet) {
		.gridbox-col(repeat(2, 1fr));
		& > .Logo {
			.grid(1 2; 1 2);
		}

		& > .Contacts {
			.grid(1 4; 2 3; start);
			& > .Contact {
				& > .Address {
					& > .Text {
						max-width: 100%;
					}
				}
			}
		}

		& > .Social {
			.grid(2 3; 1 2);
		}

		& > .FooterInfo {
			.grid(3 4; 1 2);
		}
	}

	@media all and (min-width: @laptop) {
		& > .Contacts {
			.grid(1 4; 2 3; stretch);
			& > .Contact {
				& > .Phone {
					margin-bottom: 1.5rem;
					.gridbox-gap(0.5rem);
					& > .Label {
						.text(@ColorPrimary; 1.375rem 1.125rem @semibold);
					}
					& > a {
						.text(@ColorWhite; 1.5rem 1.5rem @semibold; none; none none);
					}
				}
				& > .Address {
					& > .Metro {
						.text(fade(@ColorWhite, 50); 1rem 0.875rem @semibold);
					}
					& > .Text {
						margin-bottom: 1rem;
						.text(@ColorWhite; 1.25rem 1rem @regular);
					}
				}
			}
		}
	}
}
</style>
