<template lang="pug">
nav(ref='navigationContainer', :class='navigationClass')
	template(v-for='{ id, title, to } in navigationItems', :key='id')
		NuxtLink(:to='to.url', :data-id='id') {{ title }}
	UiDropdown(class='MoreLink', v-if='!vertical && navigationHiddenItems.length', theme='select')
		template(#label)
			span Ещё …

		template(#default)
			div(class='MoreLinksWrapper')
				template(v-for='{ id, title, to } in navigationHiddenItems', :key='id')
					NuxtLink(:to='to.url', :data-id='id', class='MoreLinksWrapper--Item') {{ title }}
</template>

<script lang="ts" setup>
import { type Menu } from '~/models/menu';

const { lessThan } = usePlatform();
const { resizeObserver } = useResize();

const {
	items = [],
	vertical = false,
	baseClass = 'Navigation',
} = defineProps<{
	items: Menu.Model[];
	vertical?: boolean;
	baseClass?: string;
}>();

const navigationContainer = ref<HTMLDivElement | null>(null);
const navigationElementsWidth = ref<number>(0);
const navigationHiddenIds = ref<string[]>([]);
const navigationCalculated = ref<boolean>(false);

/**
 * Computed navigation more button width
 */
const navigationMoreButtonWidth = computed(() => (lessThan('desktopMedium') ? 86 : 110));

/**
 * Computed navigation items
 */
const navigationItems = computed(() =>
	items.filter(
		({ id }) =>
			vertical ||
			navigationHiddenIds.value.length === 1 ||
			!navigationHiddenIds.value.includes(String(id))
	)
);

/**
 * Computed navigation items with hidden
 */
const navigationHiddenItems = computed(() =>
	items.filter(
		({ id }) =>
			!vertical &&
			navigationHiddenIds.value.length !== 1 &&
			navigationHiddenIds.value.includes(String(id))
	)
);

/**
 * Horizontal navigation more observer
 */
const horizontalNavigationMoreObserver = () => {
	if (vertical || !navigationContainer.value) return;

	navigationElementsWidth.value = 0;
	navigationHiddenIds.value = [];

	const navigationContainerWidth = navigationContainer.value.clientWidth;
	const navigationChildrenElements = navigationContainer.value.children;

	nextTick(() => {
		for (let i = 0; i < navigationChildrenElements.length; i++) {
			const element = navigationChildrenElements[i];
			const elementWidth = element.clientWidth + 8;

			navigationElementsWidth.value += elementWidth;

			if (
				!(element instanceof HTMLElement) ||
				element.className.split(' ').indexOf('MoreLink') > -1
			)
				return;

			if (
				navigationContainerWidth - navigationMoreButtonWidth.value <=
				navigationElementsWidth.value
			) {
				navigationHiddenIds.value.push(String(element.dataset.id));
			} else {
				navigationHiddenIds.value.splice(
					navigationHiddenIds.value.indexOf(String(element.dataset.id)),
					1
				);
			}
		}
		navigationCalculated.value = true;
	});
};

/**
 * Resize observer
 */
const navigationContainerObserver = resizeObserver(horizontalNavigationMoreObserver);
onMounted(() => {
	if (navigationContainerObserver && navigationContainer.value) {
		navigationContainerObserver.observe(navigationContainer.value);
	}
});
onUnmounted(() => {
	if (navigationContainerObserver) {
		navigationContainerObserver.disconnect();
	}
});

/**
 * Navigation class
 */
const navigationClass = computed(() => {
	const classes = [
		baseClass,
		...(vertical ? [`${baseClass}--vertical`] : []),
		...(!navigationCalculated.value ? [`${baseClass}--loading`] : []),
	];

	return classes.join(' ');
});
</script>

<style lang="less" scoped>
.Navigation {
	margin-right: auto;
	overflow: hidden;
	.box(flex; 100%; 1.9375rem; none; flex-start; flex-start; flex-start; wrap row);

	& > a {
		margin: 0 0.375rem 0 0;
	}
	& > a,
	& > .MoreLink {
		padding: 0.5rem 0.625rem 0.5rem 0.625rem;
		.text-nowrap();
		.text(inherit; 0.9375rem 0.875rem @semibold; none; none none);
		.box(auto; auto; fade(@ColorWhite, 50%));
		.border-radius(100rem);
		.transition(all; 250ms; 'sine');

		&:hover,
		&.router-link-active {
			.background(@ColorWhite);
		}
	}
	& > .MoreLink {
		.cursor(pointer);
		.text(@ColorPrimary) !important;
	}

	&--vertical {
		.box(grid; auto; auto; none; 0.3125rem; 1fr; auto; center; start; row);
		& > a {
			padding: 0.625rem 0 0.625rem 0;
			.text(inherit; 1.125rem 1.125rem @semibold);

			@media all and (min-width: @desktop) {
				.text(inherit; 1rem 1rem @semibold);
			}
		}
	}

	&--loading {
		padding-right: 9.5rem;
	}

	/**
	* >= 1200px
	*/
	@media all and (min-width: @desktop) {
		.box(flex; 100%; 2.0625rem; none; flex-start; flex-start; flex-start; wrap row);

		& > a,
		& > .MoreLink {
			padding: 0.5rem 1rem 0.625rem 1rem;
			.text(inherit; 0.9375rem 1rem @semibold; none; none none);
		}
	}
}

.MoreLinksWrapper {
	.box(flex; auto; auto; none; stretch; flex-start; flex-start; wrap column);
	.transition(all; 250ms; 'sine');

	&--Item {
		padding: 0.5rem 1rem;
		.cursor(pointer);
		.text(inherit; 1rem 1rem @regular; none; none none);
		&:hover {
			.text(@ColorPrimary);
			.background(fade(@ColorBase, 5%));
		}
		&.selected {
			.text(@ColorPrimary);
		}
	}
}
</style>
