<template lang="pug">
UiDropdown(v-model:shown='shown', :theme='themeDropdown', class='UiSelect')
	template(#label)
		UiButton(
			class='Phone',
			:variant='variant',
			:theme='theme',
			:size='size',
			:rounded='rounded',
			:iconRight='selectIcon',
			:iconRightSize='iconRightSize'
		) {{ selectedItem }}

	template(#default)
		div(class='UiSelectOptions')
			label(
				v-for='{ label, value } in items',
				:key='value',
				class='UiSelectOptions--Option',
				:class='{ selected: value === modelValue }',
				@click='select(value)'
			) {{ label }}
</template>

<script lang="ts" setup>
import { Themes } from '~/models/floating-vue';
import type { Common } from '~/models/common';

/**
 * Extend type
 */
type ModelValue = string | number | null;

/**
 * Define model
 */
const modelValue = defineModel<ModelValue>();

/**
 * Define props
 */
const {
	items = [],
	placeholder = 'Выберите значение',
	variant = 'white',
	theme = 'filled',
	size = 'md',
	rounded = false,
} = defineProps<{
	items: Common.SelectOption[];
	placeholder?: string;
	variant?: 'white' | 'primary' | 'secondary' | 'tertiary' | 'whatsapp';
	theme?: 'filled' | 'outline' | 'ghost';
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	rounded?: boolean;
}>();

const iconRightSize = computed(() =>
	size === 'xl' ? 'lg' : size === 'lg' ? 'md' : size === 'md' ? 'sm' : 'xs'
);
/**
 * Selected item label
 */
const selectedItem = computed(() => {
	const item = items.find(({ value }) => value === modelValue.value);
	return item ? item.label : placeholder;
});

/**
 * Select shown model
 */
const shown = ref<boolean>(false);

/**
 * Select item
 * @param value
 */
const select = (value: ModelValue) => {
	modelValue.value = value;
	shown.value = false;
};

/**
 * Select icon
 */
const selectIcon = computed(() => (shown.value ? 'system/chevron-up' : 'system/chevron-down'));

const themeDropdown = Themes.SELECT;
</script>

<style lang="less" scoped>
.UiSelect {
	.box(inline-block; auto; auto; none);

	&--Wrapper {
		padding: 0.625rem 1rem 0.6875rem 1rem;
		.cursor(pointer);
		.box(grid; auto; auto; none; 0.375rem; 1fr; auto 1rem; center; center; column);
		.text(@ColorBase; 1rem 1rem @medium; none; none none);

		& > * {
			.grid(1 span; 1 span);
		}
	}
}

.UiSelectOptions {
	.box(flex; auto; auto; none; stretch; flex-start; flex-start; wrap column);
	.transition(all; 250ms; 'sine');

	&--Option {
		padding: 0.5rem 1rem;
		.cursor(pointer);
		&:hover {
			.text(@ColorPrimary);
			.background(fade(@ColorBase, 5%));
		}
		&.selected {
			.text(@ColorPrimary);
		}
	}
}
</style>
