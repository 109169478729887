<template lang="pug">
header(class='Container')
	div(class='Header', :class='{ "Header--Main": isMainPage }')
		Logo(v-if='showHeaderLogo')

		HeaderNavigation(v-if='greaterThan("tablet")')

		HeaderContacts

		UiToggleMenu(v-if='lessThan("desktop")')

		Transition(name='slide')
			MobileMenu(v-if='lessThan("desktop") && menuToggle')

	MobileHeaderLogo(v-if='lessThan("mobileWide")')
</template>

<script lang="ts" setup>
const { lessThan, greaterThan } = usePlatform();
const { menuToggle } = useMenu();

const route = useRoute();

const isMainPage = computed(() => route.path === '/');

const showHeaderLogo = computed(
	() =>
		(!isMainPage.value && greaterThan('desktop')) ||
		(greaterThan('mobileMedium') && lessThan('desktopMedium'))
);
</script>

<style lang="less" scoped>
.Header {
	padding: 1rem 0;
	.relative(99999);
	.box(grid; 100%; auto; none; 1rem; 1fr; 1fr auto; center; center; column);

	& > .Logo {
		.grid(1 span; 1 span; center; start);
		.box(auto; 1.875rem);
	}

	& > .Navigation {
		.grid(1 span; 1 span; center; start);
	}

	& > .Contacts {
		.grid(1 span; 1 span; center; start);
	}

	& > .ToggleMenu {
		.grid(1 span; 1 span; center; end);
	}

	/**
	* >= 600px
	*/
	@media all and (min-width: @tablet-small) {
		.gridbox-col(1fr auto auto);
	}

	/**
	* >= 800px
	*/
	@media all and (min-width: @tablet) {
	}

	/**
	* >= 1000px
	*/
	@media all and (min-width: @laptop) {
		.gridbox-col(auto 1fr auto);
	}

	/**
	* >= 1200px
	*/
	@media all and (min-width: @desktop) {
		padding: 1.25rem 0;

		&.Header--Main {
			.gridbox-col(1fr auto);
			& > .Logo {
				.box(8.3125rem; auto);
			}
			& > .Contacts {
				.grid(1 span; 2 3);
				.grid-justify(end);
			}
		}

		&:not(.Header--Main) {
			.gridbox-col(auto 1fr auto);
			& > .Logo {
				.box(8.3125rem; auto);
			}
			& > .Contacts {
				.grid(1 span; 3 4);
				.grid-justify(end);
			}
		}
	}
}
</style>
