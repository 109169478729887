<template lang="pug">
div(class='HeaderLogo')
	Logo
	div(v-if='contacts.logoTitle', class='LogoTitle', v-html='$f.forceTransfer(contacts.logoTitle, 2)')
</template>

<script lang="ts" setup>
const { contacts, fetchContacts } = useContacts();

await fetchContacts();
</script>

<style lang="less" scoped>
.HeaderLogo {
	padding: 1.5rem;
	.border-radius(@BorderRadius);
	.box(grid; 100%; auto; @ColorWhite; 1rem; 1fr; auto 1fr; center; start);
	& > .Logo {
		.grid(1 span);
		.box(auto; 1.875rem);
	}
	& > .LogoTitle {
		.grid(1 span);
		.text(@ColorBase; 0.875rem 0.75rem @semibold);
	}
}
</style>
