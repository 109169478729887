import type { FooterMenu } from '~/models/footer-menu';
import { footerMenuService } from '~/services/footer-menu';

export const useFooterMenuState = () => ({
	footerMenuState: useState<FooterMenu.Model[]>('footerMenuState', () => []),
	footerMenuLoaded: useState<boolean>('footerMenuLoaded', () => false),
	footerMenuPending: useState<boolean>('footerMenuPending', () => true),
});

export const useFooterMenu = () => {
	const { footerMenuState, footerMenuLoaded, footerMenuPending } = useFooterMenuState();

	/**
	 * Fetch footer menu
	 */
	const fetchFooterMenu = async () => {
		if (footerMenuLoaded.value) return;

		footerMenuPending.value = true;

		footerMenuState.value = await footerMenuService.fetch();

		footerMenuLoaded.value = true;
		footerMenuPending.value = false;
	};

	/**
	 * Refresh footer menu
	 */
	const refreshFooterMenu = async () => {
		footerMenuLoaded.value = false;

		await fetchFooterMenu();
	};

	return {
		footerMenuState,
		footerMenuPending,

		fetchFooterMenu,
		refreshFooterMenu,
	};
};
