<template lang="pug">
div(class='ScrollShadow')
	div(class='ScrollShadowContainer', ref='scrollContainer', @scroll.passive='toggleShadow')
		slot
	span(:class='["ScrollShadow--top", shadow.top && "isActive"]')
	span(:class='["ScrollShadow--right", shadow.right && "isActive"]')
	span(:class='["ScrollShadow--bottom", shadow.bottom && "isActive"]')
	span(:class='["ScrollShadow--left", shadow.left && "isActive"]')
</template>

<script lang="ts" setup>
const { resizeObserver } = useResize();

const scrollContainer = ref<HTMLDivElement | null>(null);
const shadow = ref<{
	top: boolean;
	right: boolean;
	bottom: boolean;
	left: boolean;
}>({
	top: false,
	right: false,
	bottom: false,
	left: false,
});

/**
 * Toggle shadow controller
 */
const toggleShadow = () => {
	if (!scrollContainer.value) return;

	const hasHorizontalScrollbar =
		scrollContainer.value.clientWidth < scrollContainer.value.scrollWidth;
	const hasVerticalScrollbar =
		scrollContainer.value.clientHeight < scrollContainer.value.scrollHeight;

	const scrolledFromLeft = scrollContainer.value.offsetWidth + scrollContainer.value.scrollLeft;
	const scrolledFromTop = scrollContainer.value.offsetHeight + scrollContainer.value.scrollTop;

	const scrolledToTop = scrollContainer.value.scrollTop === 0;
	const scrolledToRight = scrolledFromLeft >= scrollContainer.value.scrollWidth;
	const scrolledToBottom = scrolledFromTop >= scrollContainer.value.scrollHeight;
	const scrolledToLeft = scrollContainer.value.scrollLeft === 0;

	shadow.value.top = hasVerticalScrollbar && !scrolledToTop;
	shadow.value.right = hasHorizontalScrollbar && !scrolledToRight;
	shadow.value.bottom = hasVerticalScrollbar && !scrolledToBottom;
	shadow.value.left = hasHorizontalScrollbar && !scrolledToLeft;
};

/**
 * Resize observer
 */
const scrollContainerObserver = resizeObserver(toggleShadow);
onMounted(() => {
	if (scrollContainerObserver && scrollContainer.value) {
		scrollContainerObserver.observe(scrollContainer.value);
	}
});
onUnmounted(() => {
	if (scrollContainerObserver) {
		scrollContainerObserver.disconnect();
	}
});
</script>

<style lang="less" scoped>
.ScrollShadow {
	.relative(1; hidden);
	.box(100%; 100%);

	& > .ScrollShadowContainer {
		.relative(1; auto);
		.box(100%; 100%);
	}

	&--top,
	&--right,
	&--bottom,
	&--left {
		pointer-events: none;
		.opacity(0);
		.border-radius(@BorderRadiusExtra);
		.transition(opacity, transform; 250ms; 'sine');
	}

	&--top {
		.absolute(1; none; 0 0 none 0);
		.box(auto; 0.625rem);
		.border-top-radius(0);
		.gradient(fade(@ColorBase, 0%) 0%; fade(@ColorBase, 10%) 100%);
		.translateY(-0.625rem);

		&.isActive {
			.opacity(1);
			.translateY(0);
		}
	}

	&--right {
		.absolute(1; none; 0 0 0 none);
		.box(0.625rem; auto);
		.border-right-radius(0);
		.gradient(90; fade(@ColorBase, 0%) 0%; fade(@ColorBase, 10%) 100%);
		.translateX(0.625rem);

		&.isActive {
			.opacity(1);
			.translateX(0);
		}
	}

	&--bottom {
		.absolute(1; none; none 0 0 0);
		.box(auto; 0.625rem);
		.border-bottom-radius(0);
		.gradient(fade(@ColorBase, 10%) 0%; fade(@ColorBase, 0%) 100%);
		.translateY(0.625rem);

		&.isActive {
			.opacity(1);
			.translateY(0);
		}
	}

	&--left {
		.absolute(1; none; 0 none 0 0);
		.box(0.625rem; auto);
		.border-left-radius(0);
		.gradient(90; fade(@ColorBase, 10%) 0%; fade(@ColorBase, 0%) 100%);
		.translateX(-0.625rem);

		&.isActive {
			.opacity(1);
			.translateX(0);
		}
	}
}
</style>
