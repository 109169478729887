<template lang="pug">
div(class='Contacts', v-if='contacts')
	UiSelect(
		placeholder='Выбор города',
		v-model='contactsCurrentId',
		:items='contactsSelectList',
		:variant='lessThan("desktopMedium") ? "secondary" : "white"',
		:theme='lessThan("desktopMedium") ? "outline" : "filled"',
		:size='lessThan("mobileMedium") ? "xs" : lessThan("desktopMedium") ? "sm" : "md"',
		rounded
	)

	div(v-if='lessThan("tablet")', class='Address') {{ contacts.addressShort }}

	UiButton(
		v-if='greaterThan("mobileWide") && contacts?.phone',
		class='Phone',
		:href='$f.tel(contacts.phone)',
		:variant='lessThan("tablet") ? "secondary" : "primary"',
		:theme='lessThan("tablet") ? "outline" : "filled"',
		:size='lessThan("mobile") ? "xs" : lessThan("desktopMedium") ? "sm" : "md"',
		rounded
	) {{ contacts.phone }}
</template>

<script lang="ts" setup>
const { fetchContacts, contactsCurrentId, contactsSelectList, contacts } = useContacts();
const { lessThan, greaterThan } = usePlatform();

await fetchContacts();
</script>

<style lang="less" scoped>
.Contacts {
	.box(grid; auto; auto; none; 1rem; 1fr; auto auto; center; center; column);
	& > .Address {
		.text(@ColorBase; 0.875rem 0.75rem @medium);

		@media all and (min-width: @mobile-small) {
			.text(@ColorBase; 1rem 0.875rem @medium);
		}
	}
}
</style>
